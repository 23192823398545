import { Injectable, Provider, inject } from '@angular/core'
import {
	HttpEvent,
	HttpInterceptor,
	HttpHandler,
	HttpRequest,
	HTTP_INTERCEPTORS,
	HttpErrorResponse,
	HttpResponse,
} from '@angular/common/http'

import { RefreshTokenService } from '../_services/refresh-token.service'

import { Observable, throwError } from 'rxjs'
import { catchError, switchMap, tap } from 'rxjs/operators'
import { EnvironmentService } from '../../../../environments/environment.service'
import { Router } from '@angular/router'

@Injectable()
export class refreshTokenInterceptor implements HttpInterceptor {
	private isRefreshing = false
	private apiEnvironmentService = inject(EnvironmentService)

	constructor(
		private refreshToken: RefreshTokenService,
		private router: Router,
	) {}

	//Intercepts all HTTP requests
	intercept(
		req: HttpRequest<any>,
		next: HttpHandler,
	): Observable<HttpEvent<any>> {
		//Forwards the request to the next handler in the chain
		return next.handle(req).pipe(
			tap((event) => {
				if (event instanceof HttpResponse) {
				}
			}),

			//Catches any errors that occur when handling the HTTP reques
			catchError((error) => {
				if (
					error instanceof HttpErrorResponse &&
					error.status === 401
				) {
					if (error.message === 'IDENTITY_ROLES_CHANGED') {
						this.apiEnvironmentService.logout()
					}
					return this.handle401Error(req, next) //Req has properties the request query and next passes the control to the next middleware function
				}
				return throwError(() => error)
			}),
		)
	}

	private handle401Error(
		request: HttpRequest<any>,
		next: HttpHandler,
	): Observable<HttpEvent<any>> {
		//Checks if a token refresh is already in progress
		if (!this.isRefreshing) {
			this.isRefreshing = true
			return this.refreshToken.refreshToken().pipe(
				switchMap((response) => {
					const newRequest = request.clone({
						headers: request.headers.set(
							'Authorization',
							`Bearer ${response.accessToken}`,
						),
					})

					if (response === null) {
						return next.handle(newRequest)
					}

					this.isRefreshing = false
					return next.handle(newRequest) // Forwards the request with the new tokens to the next handler in the chain
				}),
				catchError((error) => {
					this.apiEnvironmentService.logout()

					const url = this.router.url
					this.router.navigate([`/auth/login`], {
						queryParams: {
							'return-url': url,
						},
					})

					this.isRefreshing = false

					return throwError(() => error)
				}),
			)
		}

		return next.handle(request)
	}
}

export const refreshTokenProvider: Provider = [
	{
		provide: HTTP_INTERCEPTORS,
		useClass: refreshTokenInterceptor,
		multi: true,
	},
]
