import { Component, Input } from '@angular/core'
import { disableNavbar } from '../../../core/backoffice/components/navbar/navbar.component'
import { Router } from '@angular/router'
import { Title } from '@angular/platform-browser'
import { TranslateService } from '@ngx-translate/core'
import { Location } from '@angular/common'

@Component({
	selector: 'app-error-pages',
	templateUrl: './error-pages.component.html',
	styleUrl: './error-pages.component.scss',
})
export class ErrorPagesComponent {
	public accessDenied: boolean = false

	constructor(
		private router: Router,
		private title: Title,
		private translate: TranslateService,
		private location: Location,
	) {
		if (router.url === '/unauthorized') {
			this.accessDenied = true
		}
	}

	ngOnInit(): void {
		disableNavbar.set(true)
	}

	goBack(): void {
		this.location.back()
	}
}
