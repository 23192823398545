<body>
	<div class="fixed w-full h-full background-image"></div>
	<div class="messageContainer">
		@if (accessDenied) {
			<img
				ngSrc="/assets/Logo_cb.svg"
				width="200"
				height="200"
				alt="Logotipo"
				priority
			/>
			<h3>{{ 'error.accessDenied.title' | translate }}</h3>
			<i class="pi pi-lock" style="color: red"></i>
			<div class="subDiv">
				<p>{{ 'error.accessDenied.message' | translate }}</p>
				<a href="" (click)="goBack()">{{
					'routes.back' | translate
				}}</a>
			</div>
		} @else {
			<img
				ngSrc="../../../../assets/Logo_cb.svg"
				width="200"
				height="200"
				alt="Logotipo"
				priority
			/>
			<h3>{{ 'error.pageNotFound.title' | translate }}</h3>
			<i class="pi pi-times-circle"></i>
			<div class="subDiv">
				<p>{{ 'error.pageNotFound.message' | translate }}</p>
				<a href="" (click)="goBack()"
					><span id="goBack">{{ 'routes.back' | translate }}</span></a
				>
			</div>
		}
	</div>
</body>
