import { Injectable, inject } from '@angular/core'
import {
	HttpClient,
	HttpErrorResponse,
	HttpEvent,
	HttpHeaders,
} from '@angular/common/http'
import { Observable, map, throwError } from 'rxjs'
import { MessageService } from 'primeng/api'
import { environment } from '../../../../environments/environment.development'
import { applicationToken } from '../../../shared/models/applicationToken'
import { EnvironmentService } from '../../../../environments/environment.service'
import { Router } from '@angular/router'

@Injectable({
	providedIn: 'root',
})
export class RefreshTokenService {
	private apiEnvironmentService = inject(EnvironmentService)

	constructor(
		private http: HttpClient,
		private messageService: MessageService,
		private router: Router,
	) {}

	refreshToken() {
		const refreshToken = this.apiEnvironmentService.getRefreshToken()

		let headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Refresh-Token': refreshToken ?? '',
		})
		let options = { headers: headers }

		return this.http
			.post<applicationToken>(`Identity/refresh-token/`, {}, options)
			.pipe(
				map((response) => {
					const accessToken = response.accessToken
					const refreshToken = response.refreshToken

					localStorage.removeItem('accessToken')
					localStorage.removeItem('refreshToken')

					if (!accessToken || !refreshToken) {
						throw new Error('Tokens not found.')
					}

					this.apiEnvironmentService.storeAccessToken(accessToken)
					this.apiEnvironmentService.storeRefreshToken(refreshToken)

					return { accessToken, refreshToken }
				}),
			)
	}
}
