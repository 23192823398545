import { Injectable } from '@angular/core'
import { AesService } from '../aes.service'
import {
	HttpEvent,
	HttpInterceptor,
	HttpHandler,
	HttpRequest,
	HttpResponse,
	HttpErrorResponse,
} from '@angular/common/http'

import { Provider } from '@angular/core'
import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { from, lastValueFrom } from 'rxjs'
import { environment } from '../../../../environments/environment'

@Injectable()
export class aesInterceptor implements HttpInterceptor {
	constructor(private aes: AesService) {}

	async encryptRequestOG(req: any, next: HttpHandler) {
		const emailEnc = await this.aes.encrypt(req.body!.email)
		const passEnc = await this.aes.encrypt(req.body!.password)

		const encryptedReq = req.clone({
			body: { emailEnc, passEnc },
		})

		return encryptedReq
	}

	async encryptRequest(req: HttpRequest<any>, next: HttpHandler) {
		const encryptedReq = await this.aes.encrypt(
			req.serializeBody() as string,
		)

		const clone = req.clone({
			headers: req.headers,
			body: encryptedReq,
		})

		return lastValueFrom(next.handle(clone))
	}

	intercept(req: HttpRequest<any>, next: HttpHandler) {
		const urlSplit = req.url.split('/')
		let controller
		if (urlSplit.length > 1) controller = urlSplit[0]

		//only encrypts requests about Identity (login, register, ...)
		if (environment.aesEncrypt && controller == 'Identity') {
			return from(this.encryptRequest(req, next))
		}
		return next.handle(req)
	}
}

export const aesInterceptorProvider: Provider = {
	provide: HTTP_INTERCEPTORS,
	useClass: aesInterceptor,
	multi: true,
}
