import { Injectable } from '@angular/core'
import {
	HttpEvent,
	HttpInterceptor,
	HttpHandler,
	HttpRequest,
	HttpErrorResponse,
} from '@angular/common/http'
import { catchError, finalize } from 'rxjs/operators'
import { Observable, of, throwError } from 'rxjs'
import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { Provider } from '@angular/core'
import { MessageService } from 'primeng/api'
import { isLoading } from '../app.component'
import { TranslateService } from '@ngx-translate/core'
import { environment } from '../../environments/environment'

@Injectable()
export class toastsInterceptor implements HttpInterceptor {
	constructor(
		private messageService: MessageService,
		private translate: TranslateService,
	) {}
	errorToast: boolean = false

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler,
	): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			catchError((error: HttpErrorResponse) => {
				try {
					if (error.error === 'IDENTITY_UNAUTHORIZED_ACCESS')
						return next.handle(req)
					if (
						error.error.message ===
							'REQUESTS_FAILED_REFRESH_TOKEN_NOT_FOUND' ||
						error.error.message ===
							'REQUESTS_FAILED_UNABLE_TO_GENERATE_NEW_TOKENS'
					) {
						this.expiredTokenToast()
						return next.handle(req)
					}
					if (error.status === 200) {
						this.showSuccess()
					} else {
						if (
							!this.errorToast &&
							!req.url.split('/').includes('Notification')
						)
							this.showError()
						return throwError(() => error)
					}
					return throwError(() => error)
				} catch (e) {
					return next.handle(req)
				}
			}),
		)
	}

	async expiredTokenToast() {
		this.messageService.clear()
		this.messageService.add({
			severity: 'error',
			key: 'expiredToken',
			summary: 'A autenticação falhou. O token da sua sessão expirou.',
			life: environment.shortToast,
		})
		await new Promise((resolve) =>
			setTimeout(resolve, environment.shortToast),
		)
	}

	async showError() {
		this.translate
			.get('genericToasts.error')
			.subscribe(async (res: string) => {
				this.messageService.clear()
				this.messageService.add({
					severity: 'error',
					summary: 'Error',
					detail: res,
					key: 'errorToast',
					life: environment.shortToast,
				})
				await new Promise((resolve) =>
					setTimeout(resolve, environment.shortToast),
				)
			})
	}

	async showSuccess() {
		this.translate
			.get('genericToasts.success')
			.subscribe(async (res: string) => {
				this.messageService.clear()
				this.messageService.add({
					summary: 'Success',
					detail: res,
					key: 'successToast',
					severity: 'success',
					life: environment.shortToast,
				})
				await new Promise((resolve) =>
					setTimeout(resolve, environment.shortToast),
				)
			})
	}

	async showInfo() {
		this.messageService.clear()
		this.messageService.add({
			summary: 'Info',
			detail: 'Info!',
			key: 'infoToast',
			severity: 'info',
			life: environment.shortToast,
		})
		await new Promise((resolve) =>
			setTimeout(resolve, environment.shortToast),
		)
	}
}

export const toastsInterceptorProvider: Provider = {
	provide: HTTP_INTERCEPTORS,
	useClass: toastsInterceptor,
	multi: true,
}
