import {
	HTTP_INTERCEPTORS,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpInterceptorFn,
	HttpRequest,
} from '@angular/common/http'
import { Injectable, Provider } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from '../../environments/environment'
import { EnvironmentService } from '../../environments/environment.service'

@Injectable()
export class apiVersionInterceptor implements HttpInterceptor {
	constructor(private envSer: EnvironmentService) {}

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler,
	): Observable<HttpEvent<any>> {
		if (req.url.includes('assets')) {
			return next.handle(req)
		}
		if (req.url.includes('geocode')) return next.handle(req)
		if (req.url.includes('openstreetmap')) return next.handle(req)
		if (req.url.includes('osrm')) return next.handle(req)
		var clone = req.clone({
			headers: req.headers.set(
				'Authorization',
				`Bearer ${this.envSer.getAccessToken()}`,
			),
			url: `${environment.serverUrl}/api/${environment.apiVersion}/${req.url}`,
		})
		return next.handle(clone)
	}
}

export const apiVersionInterceptorProvider: Provider = {
	provide: HTTP_INTERCEPTORS,
	useClass: apiVersionInterceptor,
	multi: true,
}
